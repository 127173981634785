/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@barba/core@2.9.7/dist/barba.umd.min.js
 * - /npm/@barba/prefetch@2.1.10/dist/barba-prefetch.umd.min.js
 * - /npm/swiper@8.2.2/swiper-bundle.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/gsap@3.11.4/dist/ScrollTrigger.min.js
 * - /npm/gsap@3.11.4/dist/gsap.min.js
 * - /npm/@lottiefiles/lottie-player@1.7.1/dist/lottie-player.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
